<template>
    <div style="font-family: 'Montserrat';">
        <div style="background:#ececec88;">
            <v-app-bar height="40" flat class="hidden-sm-and-down"
            style="background:transparent;width:90%;margin:0 auto;display:block;color:#22354b">
                <v-btn text small href="mailto:info@ficenca.com"
                style="font-size:12px;
                text-transform:none;color:#222222;letter-spacing:0px;
                ">
                <i class="fas fa-envelope pr-2" style="font-size:10px;"></i>
                <strong>info@ficenca.com</strong>
                </v-btn>


                <v-btn text small href="https://goo.gl/maps/DddbeYdvZkqiDzXf8" target="_blank"
                style="font-size:12px;
                text-transform:none;color:#222222;letter-spacing:0px;
                ">
                <i class="fas fa-location-arrow pr-2" style="font-size:10px;"></i>
                <strong>Dirección</strong>
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://facebook.com/ficencacostarica`">
                    <strong><i class="fab fa-facebook-f"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://instagram.com/ficencacostarica`">
                    <strong><i class="fab fa-instagram"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://www.linkedin.com/company/ficencacr/`">
                    <i class="fab fa-linkedin-in"></i>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://api.whatsapp.com/send?phone=50670188845`">
                    <strong><i class="fab fa-whatsapp"></i></strong>
                </v-btn>
            </v-app-bar>

            <v-app-bar height="40" flat class="hidden-md-and-up"
            style="background:transparent;width:90%;margin:0 auto;display:block;color:#165198">

                <div style="margin:0 auto;display:block;">
                    <v-btn icon text small href="mailto:info@ficenca.com"
                 style="font-size:14px;">
                <i class="fas fa-envelope"></i>
                </v-btn>

                <v-btn icon text small href="https://goo.gl/maps/DddbeYdvZkqiDzXf8" target="_blank"
                 style="font-size:14px;">
                <i class="fas fa-location-arrow"></i>
                </v-btn>

                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://facebook.com/ficencacostarica`">
                    <strong><i class="fab fa-facebook-f"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://instagram.com/ficencacostarica`">
                    <strong><i class="fab fa-instagram"></i></strong>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://www.linkedin.com/company/ficencacr/`">
                    <i class="fab fa-linkedin-in"></i>
                </v-btn>
                <v-btn icon text
                target="_blank" small style="font-size:14px;"
                :href="`https://api.whatsapp.com/send?phone=50670188845`">
                    <strong><i class="fab fa-whatsapp"></i></strong>
                </v-btn>
                </div>
            </v-app-bar>
        </div>
        <v-app-bar height="100" flat color="white" style="color:22354b;width:90%;margin:0 auto;display:block;">
            <img src="@/assets/logo.png" width="200">
            <img class="ml-2 hidden-sm-and-down" 
            src="@/assets/logofiac.png" width="100">
            <!--BOTONES DE LA BARRA-->
            <v-spacer></v-spacer>
            <div style="margin:0 50px 0 0;" class="hidden-sm-and-down">
                <v-btn style="
                color: #22354b;font-size:18x;font-weight:600;
                text-transform: none;letter-spacing:0;"  rounded
                text
                @click="irPagina('/')"
                >
                    Inicio
                </v-btn>
                <v-menu
                open-on-hover
                :close-on-content-click="false"
                offset-y
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn style="
                color: #22354b;font-size:18x;font-weight:600;
                text-transform: none;letter-spacing:0;"  rounded
                v-bind="attrs"
                    v-on="on"
                text
                @click="irPagina('/Servicios')"
                >
                    Servicios
                </v-btn>
                </template>

                <v-list>
                    <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="irPagina(item.link)"
                    >
                    <v-list-item-title style="cursor:pointer;color: #22354b;font-size:18x;font-weight:400;">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>

                <v-menu
                open-on-hover
                :close-on-content-click="false"
                offset-y
                >
                <template v-slot:activator="{ on, attrs }">
                <v-badge
                overlap
                content="¡Nuevo!"
                >
                <v-btn style="
                border:solid 1px #22354b;
                color: #22354b;font-size:18x;font-weight:600;
                text-transform: none;letter-spacing:0;"  rounded v-bind="attrs"
                    v-on="on"
                text
                
                >
                    Equipos
                </v-btn>
            </v-badge>
                </template>

                <v-list>
                    <v-list-item
                    v-for="(item, index) in itemsE"
                    :key="index"
                    @click="irPagina(item.link)"
                    >
                    <v-list-item-title style="cursor:pointer;color: #22354b;font-size:18x;font-weight:400;">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>
                
            </div>
            <v-btn icon text class="hidden-md-and-up" @click="nav = !nav">
                <i class="fas fa-bars" style="font-size:40px;color:#165198"></i>
            </v-btn>
            <v-btn text rounded class="hidden-sm-and-down" to="/Contactenos"
            style="background:#165198;color:white;padding:20px 30px;letter-spacing:0;
            text-transform:none;font-weight:900
            ">
                Contáctenos
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="nav" app width="300">
            <img src="@/assets/logo.png" width="200" style="margin:50px auto;display:block;">
            <v-list shaped dense>
            <v-list-item-group
                v-model="ites"
                color="#22354b"
            >
                <v-list-item
                v-for="(item, i) in bars"
                :key="i"
                @click="irPagina(item.link)"
                >
                <v-list-item-content>
                    <v-list-item-title v-text="item.text" style="font-weight:600"></v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
            </v-list>
            <v-list dense>

                <v-list-group
                no-action
                sub-group
                >
                <template v-slot:activator>
                    <v-list-item-content>
                    <v-list-item-title style="font-weight:700">Servicios</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                    v-for="(title, i) in items"
                    :key="i"
                    @click="irPagina(title.link)"
                >
                    <v-list-item-title v-text="title.title"></v-list-item-title>
                </v-list-item>
                </v-list-group>
            </v-list>

            <v-list dense>

                <v-list-group
                no-action
                sub-group
                >
                <template v-slot:activator>
                    <v-list-item-content>
                    <v-list-item-title style="font-weight:700">Equipos</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item
                    v-for="(title, i) in itemsE"
                    :key="i"
                    @click="irPagina(title.link)"
                >
                    <v-list-item-title v-text="title.title"></v-list-item-title>
                </v-list-item>
                </v-list-group>
            </v-list>

            <v-btn text @click="irPagina('/Contactenos')" style="width:90%;margin:10px auto;display:block;
            text-align:center;background:#165198;color:white;letter-spacing:0;
            text-transform:none;font-weight:900
            ">Contáctenos</v-btn>
            <div style="position: absolute;
        bottom: 30px;
        right: 50%;
        margin-right: -50px;">
                <img
            src="@/assets/logofiac.png" width="100">
            </div>
        </v-navigation-drawer>
    </div>
</template>

<style>

.acs {
    background: #f1f1f1;
}
</style>

<script>
export default {
    data(){
        return {
            admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
            menu:false,
            
            items: [
        { title: 'Servicios Generales', link: '/Servicios'},
        { title: 'Controlador Inteligente', link: '/controlador-Inteligente'},
        { title: 'Monitoreo Remoto', link: '/monitoreo-Remoto'},
        { title: 'Alquiler de Equipos', link: '/alquiler-de-equipos'},
      ],
      itemsE: [
        { title: 'Tienda General', link: '/Equipos'},
        { title: 'Compresores de Tornillo', link: '/Categoria?cat=tornillo'},
        { title: 'Compresores de Pistón', link: '/Categoria?cat=piston'},
        { title: 'Secadores TRDY', link: '/Categoria?cat=secadore'},
        { title: 'Tanques / Reservorio', link: '/Categoria?cat=tanque'},
        { title: 'Variadores de Frecuencia', link: '/Categoria?cat=variadores'},
        { title: 'Repuestos', link: '/Categoria?cat=accesorio'},
      ],
            nav:false,ites:0,
            bars:[
                {text: "Inicio", link: "/"},
            ]
        }
    },
    methods:{
        irPagina(e){
            window.open(e, "_self")
        }
    }
}
</script>